<template>
  <div id="graphics-icons" class="graphics-wrap ">
    <div
      class="item"
      v-for="(componentName, index) in ecommerceComponents"
      @click="addELToCanvas(index)"
      :ref="'svg' + index"
      :key="index"
    >
      <component
        :class="'svg' + index"
        :is="`ecommerce${componentName}`"
      ></component>
    </div>
  </div>
</template>

<script>
import { GRAPHICS_MIXIN } from "../graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],
  computed: {
    ecommerceComponents: function() {
      return Array.from(Array(38).keys());
    },
  },
  created() {
    // https://stackoverflow.com/a/58081641/1247864
    for (let c = 0; c < this.ecommerceComponents.length; c++) {
      let componentName = this.ecommerceComponents[c];

      this.$options.components[`ecommerce${componentName}`] = () =>
        import("../assets/i/ecommerce/ecommerce" + componentName + ".vue");
    }
  },
  methods: {
    addELToCanvas(index) {
      this.$_graphicsMixin_addSVGToCanvas(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.component.scss";
</style>
